import { v4 as uuidv4 } from 'uuid'

export default {
  async insert (Vue, formData, idcliente, idsistema) {
    const idclienteTelefono = uuidv4()
    const clienteTelefono = await Vue.$offline.clienteTelefono.insertSync({
      idcliente_telefono: idclienteTelefono,
      idcliente: idcliente,
      idttelefono: formData.idttelefono,
      telefono: formData.telefono,
      horario: formData.horario,
      observaciones: formData.observaciones,
      email: formData.email,
      email_notifica_admon: formData.email_notifica_admon,
      nombre: formData.nombre,
      email_notifica_comercial: formData.email_notifica_comercial,
      email_notifica_sat: formData.email_notifica_sat,
      para_todos_los_sistemas: formData.para_todos_los_sistemas,
      estado: 1,
    })
    if (idsistema) {
      const idsistemaTelefono = uuidv4()
      await Vue.$offline.sistemaTelefono.insertSync({
        idcliente_telefono: idclienteTelefono,
        idsistema_telefono: idsistemaTelefono,
        idsistema,
        estado: 1,
        // se reordenan los contactos en el servidor
        orden: 1,
        // no se asigna en el cliente
        // control de un solo responsable por sistema desde paquetes TRG con excepción de base de datos
        es_responsable_propiedad: false,
        orden_cra: formData.orden_cra,
        codigo_cra: formData.codigo_cra,
        codigo_coaccion_cra: formData.codigo_coaccion_cra,
      })
    }
    return clienteTelefono[0]
  },
}
