<template>
  <b10-base>
    <b10-toolbar
      :title="title"
    >
      <v-btn
        slot="right"
        icon
        @click.stop="clickSubmit($refs.form)"
      >
        <v-icon>{{ $vuetify.icons.values.check }}</v-icon>
      </v-btn>
    </b10-toolbar>
    <b10-page-content
      :padding="3"
    >
      <v-form
        ref="form"
        lazy-validation
      >
        <cliente-telefono-form
          :id="null"
          :idsistema="routeQuery.idsistema"
          :has-perm-ver-personas-contacto-codigo-c-r-a="hasViewPerm(permissions.sistemas.verPersonasContactoCodigoCRA)"
          :has-perm-ver-personas-contacto-codigo-coaccion-c-r-a="hasViewPerm(permissions.sistemas.verPersonasContactoCodigoCoaccionCRA)"
          @change-values="changeFormData"
        />
      </v-form>
    </b10-page-content>
  </b10-base>
</template>

<script>
import { formPageMixin } from '@/mixins/formPageMixin'
import ClienteTelefonoForm from '../components/ClienteTelefonoForm'
import Data from './ClienteTelefonoAddData'

export default {
  components: {
    ClienteTelefonoForm,
  },
  mixins: [formPageMixin],
  async created () {
    // routeQuery.idsistema: filtro por teléfonos del sistema (crear también sistema_telefono)
    await this.initStore()
    await this.loadPage()
  },
  methods: {
    changeFormData (formData) {
      this.formData = formData
    },
    async loadPage () {
      this.title = 'Añadir persona de contacto'
    },
    async submitForm () {
      const inserted = await Data.insert(this, this.formData, this.routeParams.idcliente, this.routeQuery.idsistema)
      this.$appRouter.replace({
        name: 'offline__cliente-telefono-view',
        params: {
          idcliente: this.routeParams.idcliente,
          idcliente_telefono: inserted.idcliente_telefono,
        },
        query: {
          idsistema: this.routeQuery.idsistema,
        },
      })
    },
  }
}
</script>
